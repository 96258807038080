.buttonGetDisCode {
    background-color: #FFD200;
    color: #000000;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: large;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

    .buttonGetDisCode:hover {
        background-color: #429CF7;
    }

.copied {
    background-color: #429CF7;
}

    .copied:hover {
        background-color: #429CF7;
    }
