.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    font-family: 'Roboto', sans-serif;
}

.text {
    font-size: 10px;
    padding: 0;
    margin: 0;
}

.enddate {
    font-size: 14px;
    padding: 5px;
    margin: 0;
}