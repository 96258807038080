

/* LOGIN FORM CSS */
.loginForm {
  max-width: 30%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the form horizontally */
  font-family: Arial, sans-serif; /* Change the font family */
  text-align: left;
}

.loginForm form {
  padding: 10px; /* Add padding */
  margin-bottom: 10px;
}

.loginForm input[type="email"],
.loginForm input[type="checkbox"] {
  width: calc(100% - 10px); /* Make input fields slightly smaller than form width */
  padding: 10px 4px; /* Add padding */
  border-style:solid;
  border-radius: 14px; /* Add border radius */
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5); /* Negative box shadow */
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

/* Custom round checkbox style */
/* Custom round checkbox style */
.loginForm input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 33px; /* Set width and height to create a square shape */
  height: 33px;
  border-radius: 50%; /* Make it round */
  outline: none; /* Remove outline */
  background-color: #ffffff; /* Change background color when unchecked */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 10px;
  position: relative; /* Position relative for pseudo-element */
}

/* Pseudo-element for checkmark */
.loginForm input[type="checkbox"]::before {
  content: ""; /* Required for pseudo-element */
  position: absolute; /* Position relative to the checkbox */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the checkmark */
  width: 15px; /* Set size of the checkmark */
  height: 15px;
  border-radius: 50%; /* Make it round */
  background-color: #000000; /* Change color of the checkmark */
  display: none; /* Hide by default */
}

/* Show checkmark when checkbox is checked */
.loginForm input[type="checkbox"]:checked::before {
  display: block; /* Show the checkmark */
}

.checkbox-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
}

/* Flexbox layout to align items horizontally */
.checkboxLabel {
  display: flex;
  align-items: center;
}

.checkbox-text {
  font-size: small;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

/* Move the checkbox to the right inside the form */
.checkboxLabel input[type="checkbox"] {
  /* Other styles */
  margin-left: auto; /* Push the checkbox to the right */
  margin-top: 10px; /* Adjust the vertical alignment */
  margin-bottom: 10px;
  pointer-events:all;
}


.loginForm button[type="submit"] {
  width: 100%; /* Make button full width */
  padding: 10px 2px; /* Add padding */
  background-color: #0050A1; /* Change button background color */
  border: none; /* Remove border */
  border-radius: 25px; /* Add border radius */
  cursor: pointer; /* Add cursor pointer */
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
  font-size: xx-large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #fff; /* Change button text color */
  /* Add shadow */
}

/* CREATE FORM CSS */
.createForm {
  width: 30%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the form horizontally */
  font-family: Arial, sans-serif; /* Change the font family */
  text-align: left;
}

.createForm form {
  padding: 5px; /* Add padding */
}

.createForm input[type="username"] {
  width: calc(100% - 10px); /* Make input fields slightly smaller than form width */
  padding: 10px 4px; /* Add padding */
  border-style: solid;
  border-radius: 14px; /* Add border radius */
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5); /* Negative box shadow */
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.createForm button[type="submit"] {
  width: 100%;
  padding: 10px 2px;
  background-color: #0050A1;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
  font-size: xx-large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #fff;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .input {
    font-size: 16px;
  }
}

/* Responsive */
@media only screen and (max-width:450px) {
  .loginForm {
    min-width: 90%; 
  }
  .createForm {
    min-width: 90%; 
  }
  .loginForm button[type="submit"] {
    padding: 8px 2px;
    font-size: x-large;
  }
  .createForm button[type="submit"] {
    padding: 8px 2px;
    font-size: x-large;
  }
}
@media only screen and (min-width: 451px) and (max-width: 850px) {
  .loginForm {
    min-width: 50%; 
  }
  .createForm {
    min-width: 50%; 
  }
}