.modal-dialog {
    max-width: 600px;
    margin: 0 auto;
}

.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
}

.modal-header {
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: #fff;
}

.modal-title {
    margin-top: 0;
}

.modal-body p {
    margin-bottom: 20px;
}

.modal-body address {
    margin-bottom: 20px;
}

.modal-body a {
    color: #007bff;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #1d1d1d;
    background-color: #f1f1f1;
    font-size: 24px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
}

.close:hover {
    opacity: 1;
    background-color: #007bff;
}