/* Leaderboard.css */
.leaderboardbody {
  padding: 20px;
  height: 100vh;
}

.leaderboard {
  text-align: center;
}

.button-container {
  text-align: center;
}

.button {
  background-color: black;
  max-width: 150px;
  border: none;
  border-radius: 20px;
  padding: 0px 10px;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.blackBox2 {
  width: 50%;
  margin: 0 auto;
}

.box2 {
  background-color: black;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Stilar för tabell */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

/* Stilar för tabellrubriker */
.table th {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

/* Stilar för tabellceller */
.table td {
  border-top-style: solid;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

/* Stilar för laddningsmeddelande */
.loading-message {
  font-size: large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: grey;
}

/* Stilar för markerad rad */
.highlighted-row {
  background-color: #BDDEC5;
}

.leaderboard-table-wrapper {
  max-height: 300px;
  overflow-y: auto;
  max-width: 40%;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
}

.leaderboard-table-body {
  text-align: left;
}

/* Responsive */
@media only screen and (max-width:450px) {
  .leaderboard-table-wrapper {
    min-width: min-content;
  }

  .table td {
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 850px) {
  .leaderboard-table-wrapper {
    min-width: min-content;
    padding: none;
  }
}

@media only screen and (max-width: 400px) {
  .leaderboard-table-wrapper {
    min-width: min-content;
    padding: none;
  }
}