/* App.css */

/* Define scrolling behavior */
/*tog bort hidden här*/
.no-scroll {
  overflow: visible;
  height: 100vh;
}

body{
  background-image: url('./images/bg.jpg'); /* Background image */
  background-size: cover; /* Cover the entire background */

}

.header {
  text-align: center;
  margin: 4%;
}
/* Svensk Husman Image*/
.SH{
  width: 10%;
  height: auto;
}
#htpGif{
  width: 20%;
  height: auto;
}
/* Responsive */
@media only screen and (max-width:450px) {
  .header {
    padding: 10%;
  }
  body{
    background-color: #BDDEC5;
    background-image: none;
  }
  .SH {
    width: 30%; 
  }
  #htpGif{
    width: 80%;
  }
}
@media only screen and (min-width: 451px) and (max-width: 850px) {
  body {
    background-color: #BDDEC5;
  }
  .SH {
    width: 15%;
  }
  #htpGif{
    width: 40%;
  }
}

/* Text CSS */
.underline-text {
  text-decoration: underline;
}
.blue-text {
  color: #0050A1;
  pointer-events: all;
}
.blue {
  font-size: large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #0050A1;
  margin: 30px 0px 0px 0px;
}
.blueL {
  font-size: xx-large;
  font-family: 'Fascinate', cursive;
  color: #0050A1;
  padding-top: 10px;
  margin: 0;

}
.bigBlueL {
    font-size: 40px;
    font-family: 'Fascinate', cursive;
    color: #0050A1;
    margin: 0px;
}
.bigBlackL {
  font-size: 40px;
  font-family: 'Fascinate', cursive;
  color: #000000;
}
.blackXL {
  font-size:x-large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.blackL {
    font-size: large;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.whiteL {
  margin: 5px;  
  font-size: large;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: white;
}
.blackM {
  font-size: medium;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.blackS {
  font-size: small;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.blackS2 {
  font-size: small;
  font-family: 'Roboto', sans-serif;
}
.error{
  font-size: small;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: red;
  display: inline; /* or inline-block */
}


