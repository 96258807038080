.body {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
}

.motive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: none;
  width: 50%;
  border-radius: 25px;
}

#motive {
  width: 30%;
  height: auto;
}

.reward-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.howToPlay {
  background-color: rgb(176, 70, 78);
  border-radius: 20px;
  padding: 20px 50px;
  width: 25%;
  height: 200px;
  margin: 0 auto;
}

.video-player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  position: relative;
}

.video-player video {
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 50px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
  font-size: 20px;
}

.close-button:hover {
  background-color: #429CF7;
}

/* Responsive */
@media only screen and (max-width:450px) {

  .motive {
    width: 80%;
  }

  .howToPlay {
    width: 50%;
  }

}

@media only screen and (min-width: 451px) and (max-width: 850px) {
  .body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .motive {
    width: 70%;
    height: auto;
  }
}