/*GameOver.css*/
.body-gameover {
    text-align: center;
    height: 100vh;
}

.blueBox {
    max-width: 100%;
    margin: 0 auto;
    text-decoration: none;
}

.boxPlayAgain {
    text-decoration: none;
    margin: 0 auto;
    background-color: #0050A1;
    text-align: center;
    max-width: 300px;
    border: none;
    border-radius: 25px;
    padding: 10px 2px;
    font-size: xx-large;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #fff;
    transition-duration: 0.4s;
    box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

a {
    text-decoration: none;
    /* Tar bort textdekoration för länkar */
}

.getCoupon {
    background-color: #fff;
    border-style: none;
    padding: 5px 2px;
    margin-top: 10px;
    width: 200px;
    box-shadow: 0 2px 0.25em rgba(0, 0, 0, 0.3);
    /* Add shadow */
    border-radius: 15px;
    /* Add border radius */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#Gyllebulle {
    width: 15%;
    height: auto;
    rotate: -90deg;
    padding: 10px;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Bakgrundsfärg för att mörklägga bakgrunden */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* För att lägga popupen över all annan innehåll */
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    position: relative;
    max-width: 60%;
    text-align: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: black;
    background-color:transparent;
}

#lussebulle {
    width: 100px;
    /* Anpassa storlek efter behov */
    height: auto;
    margin: 20px auto;
    /* Centrera bilden */
}

.blackL {
    font-size: 24px;
    color: black;
}

.blackS {
    font-size: 18px;
    color: black;
}


.game_over_graphic {
    width: 15%;
}

/* Responsive */
@media only screen and (max-width:450px) {

    .body-gameover {
        padding-left: 10px;
        padding-right: 10px;
    }

    .game_over_graphic {
        height: auto;
        width: 30%;
    }

    .blueBox {
        max-width: 60%;
    }
}

@media only screen and (min-width: 451px) and (max-width: 850px) {
    .game_over_graphic {
        height: auto;
        width: 20%;
    }
}