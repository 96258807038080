.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.admin-form-container {
  margin-bottom: 20px;
  background-color: #BDDEC5;
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
}

.admin-login-form {
  margin-bottom: 10px;
}

.admin-login-form label {
  margin-left: 10px;
}

.admin-login-form input {
  margin-right: 10px;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
}
.admin-login-form button[type="submit"] {
  background-color: #007bff; /* Bakgrundsfärg */
  color: white; /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 5px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bold;
}
.admin-login-form button[type="submit"]:hover {
  background-color: #0056b3; /* Bakgrundsfärg vid hovring */
}

.admin-error-message {
  color: red;
  margin-top: 10px;
}



/* CSS efter inloggning: */
.admin-loggedin{
  text-align: left;
}
.black-line {
  width: 100%; /* Adjust the width as needed */
  height: 2px; /* Adjust the height as needed */
  background-color: black; /* Set the background color to black */
  margin: 20px 0; /* Add some space above and below the line */
}


.admin-form {
  margin-bottom: 10px;
}

.admin-form label {
  margin-left: 10px;
  font-size: small;
  font-weight: bold;
}

.admin-form input {
  margin-right: 10px;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
}
.admin-form button[type="submit"] {
  background-color: #ffffff; /* Bakgrundsfärg */
  color: rgb(0, 0, 0); /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 20px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bold;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
}
.admin-form button[type="submit"]:hover {
  background-color: #ffffff; /* Bakgrundsfärg vid hovring */
}

.randomWinner{
  background-color: #ffffff; /* Bakgrundsfärg */
  color: rgb(0, 0, 0); /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 20px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bold;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
}

.resetGame{
  background-color: #f22d2d; /* Bakgrundsfärg */
  color: rgb(255, 255, 255); /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 20px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bolder;
  width: 100%;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
}
.confirmation-modal{
  text-align: center;
}
.confirmReset{
  background-color: #f22d2d; /* Bakgrundsfärg */
  color: rgb(255, 255, 255); /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 20px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bolder;
  width: 40%;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
}
.cancelReset{
  background-color: #079813; /* Bakgrundsfärg */
  color: rgb(255, 255, 255); /* Textfärg */
  border: none; /* Ta bort gränsen */
  margin: 5px;
  padding: 10px 20px; /* Padding för att ge knappen utseendet */
  border-radius: 20px; /* Avrunda hörnen */
  cursor: pointer; /* Visa handpekaren vid hovring */
  font-weight: bolder;
  width: 40%;
  box-shadow: 0 4px 0.25em rgba(0, 0, 0, 0.3); /* Add shadow */
}